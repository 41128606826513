<template>
  <div>
    <upload-file-sidebar />
    <div v-if="loading" class="d-flex justify-content-center m-2">
      <b-spinner variant="primary" />
    </div>
    <b-card v-else>
      <b-card-header class="p-0 mb-1">
        <b-card-title>Histórico de arquivos</b-card-title>
        <b-col cols="12" md="6">
          <b-row class="align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="handleUploadFileSidebar(customerFiles)"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              <span class="text-nowrap">Novo arquivo</span>
            </b-button>
          </b-row>
        </b-col>
      </b-card-header>
      <b-table
        id="files-table"
        :items="items"
        striped
        :per-page="perPage"
        :current-page="currentPage"
        borderless
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontrados arquivos"
        :busy="loading"
      >
        <template #cell(id)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.index + 1
            }}</span>
          </div>
        </template>

        <template #cell(file_name)="data">
          <div class="text-wrap">
            <b-button
              variant="link"
              style="padding: 0"
              @click="resumeDownload(data.item)"
              :disabled="downloading.includes(data.item.id)"
            >{{ data.item.file_name }}
          </b-button>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-spinner 
            v-if="downloading.includes(data.item.id)" 
            variant="primary" 
          />
          <b-dropdown 
            variant="link" 
            no-caret right 
            v-else
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="deleteFile(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Remover</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="files-table"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BPagination,
  BSpinner,
  BCardHeader,
  BButton,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import UploadFileSidebar from "../components/UploadFileSidebar";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BCardTitle,
    BPagination,
    BTable,
    BSpinner,
    BCardHeader,
    BButton,
    BCol,
    BRow,
    UploadFileSidebar,
    BDropdown,
    BDropdownItem
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      downloading: [],
      perPage: 3,
      currentPage: 1,
      tableColumns: [
        { key: "id", label: "#", sortable: true },
        { key: "file_name", label: "Arquivos", sortable: true, class: "w-100" },
        { key: "file_uploaded_at", label: "Data de upload", sortable: true, class: "w-100" },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      customerFiles: types.CUSTOMER_FILES,
    }),
    items: function () {
      return this.customerFiles.files;
    },
    rows() {
        return this.items.length
      }
  },
  async created() {
    await this.get();
  },
  methods: {
    ...mapActions({
      getCustomerFiles: types.GET_CUSTOMER_FILES,
      openUploadFileSidebar: types.OPEN_UPLOAD_FILE_SIDEBAR,
      deleteCustomerFile: types.DELETE_CUSTOMER_FILE,
      downloadCustomerFile: types.DOWNLOAD_CUSTOMER_FILE,
    }),
    async get() {
      this.loading = true;
      await this.getCustomerFiles(this.$route.params.id)
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os arquivos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async handleUploadFileSidebar(customer) {
      await this.openUploadFileSidebar({
        id: customer?.id,
        saveAction: () => {
          this.get();
        },
      });
    },
    async deleteFile(item) {
      try {
        const result = await this.$swal({
          title: "Tem certeza?",
          text: `Você removerá o arquivo ${item.file_name}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Remover",
          cancelButtonText: "Cancelar",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary ml-1",
            htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
          },
        });
        if (result.value) {
          await this.deleteCustomerFile(item.id);
          this.get();
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: `O arquivo ${item.file_name} foi removido`,
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: `Ocorreu um erro ao remover arquivo ${item.file_name}. Entre em contato com o setor de TI.`,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loading = false;
      }
    },
    resumeDownload(item) {
      this.downloading.push(item.id);
      this.downloadCustomerFile({id: item.id, file_name: item.file_name})
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.downloading = this.downloading.filter(id => id !== item.id)
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.link-download {
  color: #f26237;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
